const messages = {
  home: "Home",
  aboutUs: "About Us",
  foundUs: "Found Us",
  contactUs: "Contact Us",
  products: "Products",
  programs: "Programs",
  myAccount: "My Account",
  cart: "Cart",
  logIn: "Log In",
  register: "Register",
  logOut: "Log Out",
  alcoholWarning: "Excessive alcohol consumption <br/>is harmful to health",
  phraseDonMiguel: "“I have never lost faith in Dominicans as people, in democracy as a system, and in the Dominican Republic as a country.”",
  termAndConditions: "Terms and Conditions",
  privacyPolicies: "Privacy Policies",
  contacUs: "Contact Us",
  copyRight: "Copyright Reserved"
};
export default messages;
