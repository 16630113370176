<template>
  <v-container fluid class="bg-img fill-height pa-10">
    <v-row>
      <v-col cols="12" class="text-center">
        <v-img src="@/assets/logo1.svg" class="logo_age_filter logo" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="6" md="6" lg="3" xl="6">
        <v-row justify="center" align="center" class="mt-2 mt-lg-n3 mt-xl-0">
          <p class="josefin-light white-black p text-center">
            Para Don Miguel es importante conocer a cada visitante que recibe en sus preciadas
            bodegas.
          </p>
        </v-row>
        <v-row justify="center" class="mt-lg-n5 mt-xl-0">
          <p class="copperplate mt-10 white-black text-center">
            Por favor introduzca su fecha de nacimiento
          </p>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-5" justify="center" no-gutters>
      <v-col class="d-none d-md-flex d-md-none mb-10" sm="1" md="1" lg="1" xl="1">
        <v-img auto src="@/assets/orn_peq_right.svg" class="logo_age_filter mr-5" />
      </v-col>
      <v-col class="d-flex pa-0" cols="4" sm="2" lg="1" xl="1">
        <v-text-field
          class="age_imput_background"
          label="DD"
          v-model="info.birthday.day"
          solo
          autocomplete="false"
        ></v-text-field>
      </v-col>
      <v-col class="d-none d-md-flex d-md-none mb-10" sm="1" md="1" lg="1" xl="1">
        <v-img contain src="@/assets/circulito.svg" class="logo_age_filter circle" />
      </v-col>
      <v-col class="d-flex pa-0" cols="4" sm="2" lg="1" xl="1">
        <v-text-field
          class="age_imput_background"
          label="MM"
          autocomplete="false"
          v-model="info.birthday.month"
          solo
        ></v-text-field>
      </v-col>
      <v-col class="d-none d-md-flex mb-10" sm="1" md="1" lg="1" xl="1">
        <v-img contain src="@/assets/circulito.svg" class="logo_age_filter circle" />
      </v-col>
      <v-col class="d-flex age_imput_background pa-0" cols="4" sm="2" md="2" lg="1" xl="1">
        <v-text-field
          class="age_imput_background"
          label="AAAA"
          v-model="info.birthday.year"
          solo
          autocomplete="false"
        ></v-text-field>
      </v-col>
      <v-col class="d-none d-md-flex d-md-none mb-10" sm="1" md="1" lg="1" xl="1">
        <v-img src="@/assets/orn_peq_left.svg" class="logo_age_filter ml-5" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-btn
        class="btn_access"
        v-bind:class="{ btn_access_active: isLegal }"
        @click="getAccess"
        >ACCEDE</v-btn
      >
    </v-row>
    <v-row justify="center">
      <v-col class="d-flex" cols="4">
        <v-img container src="@/assets/Ornamento_Grande.svg" class="logo_age_filter" />
      </v-col>
    </v-row>
    <v-row justify="center" class="d-none">
      <v-col class="d-flex" cols="12" lg="2">
        <v-select
          :items="countries"
          solo
          label="Country"
          class="select_country_lenguage"
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="12" lg="2">
        <v-select
          :items="language"
          solo
          label="Language"
          class="select_country_lenguage"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import { required } from "vuelidate/lib/validators";

export default {
  created() {
    this.initInfo();
  },
  data() {
    return {
      info: {
        birthday: {
          day: null,
          month: null,
          year: null
        },
        country: "",
        language: ""
      }
    };
  },

  validations: {
    info: {
      birthday: {
        day: {
          required
        },
        month: {
          required
        },
        year: {
          required
        }
      }
    }
  },

  computed: {
    birthdaytring() {
      return `${this.info.birthday.year}-${this.info.birthday.month}-${this.info.birthday.day}`;
    },
    isLegal() {
      return moment().diff(this.birthdaytring, "year") >= 18 && !this.$v.$invalid;
    },
    ...mapState(["language", "countries"])
  },
  methods: {
    ...mapActions(["saveInfo"]),
    initInfo() {
      const info = localStorage.getItem("info");
      if (info) {
        this.saveInfo(JSON.parse(info));
      }
    },
    getAccess() {
      if (this.isLegal) {
        this.saveInfo(this.info);
        this.$router.push("/");
      }
    }
  }
};
</script>
