// initial state
const programs = {
  state: () => ({
    programsList: [
      {
        name: "<span class='josefin-medium' >Ron Don Miguel</span></br> PRIVATE BARREL",
        image: "/barriles-about.jpg",
        url: "/programs/private-barrel",
      },
      {
        name: "<span class='josefin-medium' >Ron Don Miguel </span> </br>PRIVATE STOCK",
        image: "/barriles-about.jpg",
        url: "/programs/private-stock",
      }
    ],
    program: null
  }),
  getters: {
    getPrograms: (state) => state.programsList,
    getProgram: (state) => state.program,
  },
  actions: {
    commitGetPrograms({ commit }, data) {
      commit("setProgram", data);
    }
  },
  mutations: {
    setProgram(state, data) {
      state.program = data;
    }
  },
  namespaced: true
};

export default programs;
