const messages = {
  shippingInformation: "Information",
  shippingInformationPreview: "Shipping Information",
  billingInformation: "Billing Information",
  foundUs: "Found Us",
  foundUsMessages: "Currently we do not have shipments available to your location, however, here we offer you our closest points of sale.",
  resumen: "Resumen",
  personalInformation: "Personal Information",
  phone: "Phone",
  addressShipping: "Shipping Address",
  address: "Address",
  province: "Province",
  municipality: "Municipality",
  postCode: "Post Code",
  btnNext: "Next",
  btnUpdate: "Update",
  shippingMethods: "Shipping Methods",
  billingMethods: "Billing Methods",
  btnUseAddress: "Use this address",
  btnSave: "Save",
  btnChange: "Change",
  btnPay: "Pay"
};

export default messages;
