const messages = {
  aboutTitle: "Sobre nosotros",
  aboutDescription: "Nuestro objetivo es darle continuidad al legado de Don Miguel Barceló Pascual, hombre visionario, reconocido como el mejor ronero dominicano de nuestra historia, que llevó el ron dominicano a las más altas cimas de calidad y ventas en la República Dominicana, fue quien dió inicio a la internacionalización del Ron Dominicano ante el mundo, utilizando sus mejores y más preciadas fórmulas personales, celosamente guardadas y que fueron transmitidas a su hijo Julián. Orgullosos de este legado, presentamos estos productos, de altísima calidad; ideados para esos consumidores amantes del buen ron, que experimentan lo diferente y sólo se conforman con lo excepcional.",
  btnKnowMore: "Conoce Más",
  mission: "MISIÓN",
  missionDescription: '“Darle continuidad al legado de Miguel Barceló Pascual, resaltando sus acciones sociales, culturales y empresariales; ofertando productos innovadores y de altísima calidad, que satisfagan las necesidades y expectativas de los consumidores, despertando emociones y sensaciones”.',
  view: "VISIÓN",
  viewDescription: '“Satisfacer los deseos y expectativa de los consumidores siendo una empresa vanguardista e innovadora con una variada cartera de productos de insuperable calidad que se anticipa al mercado trazando pautas, convirtiéndose de esta manera en la empresa ronera Dominicana de mayor prestigio.”',
  values: "VALORES",
  value1: "Familia",
  value2: "Tradición",
  value3: "Orgullo",
  value4: "Altruismo",
  value5: "Calidad",
  value6: "Compromiso",
  value7: "Excelencia",
  value8: "Honestidad",
  value9: "Humildad",
  value10: "Integridad",
  value11: "Pasión",
  theGreatestSenseOfQuality: "EL MAYOR SENTIDO DE LA CALIDAD",
  theGreatestSenseOfQualityDescription: "Rigurosos Estándares de producción que van más allá del deber para lograr nuestra calidad superlativa: los mejores rones del mundo.",
  whoIsADonMiguel: "¿QUIÉN ES UN DON MIGUEL?",
  whoIsADonMiguelDescription: "Es íntegro, noble y transparente, comprometido con el corazón y la mente, que busca la excelencia en cada acción que desarrolla. Ciudadano responsable que hace la diferencia al ayudar a construir y apoyar comunidades dominicanas. Atrevido e innovador, siempre teniendo en cuenta sus principios, valores y tradiciones. Es altruista, cree en el trabajo del ser humano, su capacidad de crecimiento y desarrollo.",
  ourShield: "NUESTRO ESCUDO",
  ourShieldDescription: "El escudo de Ron Don Miguel está compuesto por cinco partes que expresan los elementos esenciales para la producción de los mejores rones Dominicanos. Agua, brotes de caña, alambique, nuestros tres pilares y la bandera dominicana se conjugan de manera diagonal del izquierdo inferior al izquierdo superior. El agua representa la vida; el brote de caña, de donde proviene el azúcar; el alambique, donde destilamos nuestros alcoholes; Los tres pilares que expresan nuestros valores familiares, tradiciones y orgullo; y la bandera dominicana que manifiesta el compromiso con nuestro país.",
  innovation: "Innovación",
  innovationDescription: "Ron Don Miguel Homenaje es el primer ron del mundo en incorporar tapones de infusión en sus botellas. Esta innovación aplicada en nuestros productos persiguen un objetivo claro: hacer de cada botella una experiencia única e irrepetible. Esto se ha logrado gracias a los tapones de infusión de Oak Master ®. Estos tapones con dos tipos de roble (americano o francés) y dos tipos de tostado (medio plus y fuerte), logran lo que registramos como “Transformación en Botella®”. Al aplicarlos potencian las características analíticas y sensoriales de este ya extraordinario ron previamentente envejecido por 60 meses. <br/><br/>El aporte sensorial de los tapones inicia tan pronto son introducidos en botella, al permanecer en contacto con el ron continúan cediendo los aromas y sabores del roble mejorando sustancialmente sus caracteristicas. Es el aporte de las nuevas generaciones a esta historia de calidad.",
};

export default messages;
