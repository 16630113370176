const messages = {
  title: "Terms and Conditions",
  termsOfUse: "Terms of Use",
  termsOfUseDescription: "Welcome to Ron Don Miguel. Ron Don Miguel and its affiliates provide their products subject to the following conditions. If you visit or buy at www.rondonmiguel.com, you accept these conditions. Please read them slowly and carefully.",
  electronicCommunications: "Electronic Communications",
  electronicCommunicationsDescription: "When you visit Ron Don Miguel’s online page, or send us an email, you are communicating with us electronically. With this action you agree to maintain the electronic form as a means of communication. <br/><br/>We will communicate with you via mail or through notifications on our page. You agree that all agreements, purchase responsibilities, notifications, and any other type of communication that we send you electronically satisfy any legal requirements that may arise.",
  copyright: "Copyright",
  copyrightDescription: "All content on this page, texts, graphics, logos, photographs, buttons, audio, digital downloads, data compilations, and software is property of Ron Don Miguel SRL and is guaranteed by the copyright laws of the Dominican Republic.",
  licensesAndAccessToTheWebsite: "Licenses and Access to the Website",
  licensesAndAccessToTheWebsiteDescription: "This Ron Don Miguel website cannot be reproduced, duplicated, copied, sold, or used for any commercial purpose, without a written agreement with Ron Don Miguel SRL. Any violation of the specifications mentioned above will be processed legally.",
  rightsOfUse: "Rights of Use of www.rondonmiguel.com",
  rightsOfUseDescription: "The mere visit to this site, in which certain products are offered, does not impose any obligation to you, unless you have accepted the conditions offered by the company, in the manner indicated in these terms and conditions. www.rondonmiguel.com is for the exclusive use of individuals who wish to review, consider, purchase, or participate in transactions involving Ron Don Miguel on your own behalf (or in the case of a gift, on behalf of the recipients).",
  productInformation: "Product Information",
  productInformationDescription: "Exclusive products for the web will only be for sale at www.rondonmiguel.com. In some cases, the products displayed for sale at the different Ron Don Miguel sale points may not be available at www.rondonmiguel.com.",
  photographs: "Photographs",
  photographsDescription: "Ron Don Miguel displays product images with the greatest possible precision and clarity. However, the display of the color of the product will depend on the monitor from which you access our website. The photographs of the products presented on the website are referential, their purpose is to give you an idea of the product, but not necessarily references about the product itself, so there may be variants between the photographs on www.rondonmiguel.com and the product received.",
  inventory: "Inventory",
  inventoryDescription: "Ron Don Miguel is in charge of updating the site and the constant reviewing of the products displayed. However, Ron Don Miguel can modify and discontinue products at any moment without incurring any liability to you as a customer.",
  inventoryRuleOne: "Products that are out of stock will be displayed on the website with a “Out of Stock” restriction",
  inventoryRuleTwo: "By virtue of this, Ron Don Miguel reserves the right to not approve a purchase request and consequently not dispatch a product if it does not have the requested inventory.",
  inventoryRuleThree: "In case there is no inventory of the chosen material, a Gift Card is automatically generated.",
  priceAndPromotions: "Prices And Promotions",
  priceAndPromotionsDescription: "The prices and promotions offered at www.rondonmiguel.com are not necessarily the same as those offered by other sales channels used by Ron Don Miguel, such as physical stores, TV, catalogs, or others, unless stated on this site or in the advertising made by Ron Don Miguel for each promotion. <br/><br/>The prices and/or promotions of the products and services published on this site, are valid only if they appear on it. Ron Don Miguel may modify any information contained in this site.<br/><br/>Regular prices are shown in BLACK.<br/><br/>The prices of products offered in the site are expressed in American dollars, indicating the current rate on the page, unless stated otherwise. <br/><br/>The offered prices correspond exclusively to the value of the product offered and do not include transport, handling, shipping, accessories, or any additional item that are not expressly described. If there is a typing error in any of the product prices, and the correct price of the item is higher that the one shown on the page, Ron Don Miguel will contact you before the product is shipped, and/or we will cancel the order and notify you. In case the price is lower, the difference will be deducted from the final purchase order.",
  knowledgeOfTheOrders: "Knowledge of the Orders Placed Through This Site",
  knowledgeOfTheOrdersDescription1: "Through www.rondonmiguel.com, you will purchase products using the mechanisms that the same e-commerce offers for it. Any acceptance of the offer will be subjected to the suspensive condition that Ron Don Miguel validates the transaction. Consequently, for any operation carried out on the Ron Don Miguel online page, confirmation and/or validation or verification by Ron Don Miguel will be a requirement for the formation of consent. To validate the transaction, Ron Don Miguel must verify:",
  check1: "That the data registered by you on the page coincides with those provided when accepting the offer.",
  check2: "Validation of financial information.",
  check3: "That the payment is credited by you as the User.",
  check4: "That the order is made by people over the age of 18, with a valid ID or passport.",
  knowledgeOfTheOrdersDescription2: "We will send you a confirmation of your order through the email you have given us during your registration. Consent will be understood to be formed from the moment this written confirmation is sent to you and in the place where it was issued.  If the purchase process is not approved, Ron Don Miguel will send an email to the customer to inform that his order has been canceled and a payment has not been made. As an anti-fraud security measure, Ron Don Miguel and the contracted payment platform will be able to contact you to verify the legality of the purchase. If it is not located, Ron Don Miguel will void said transaction. In this case, the payment platform will communicate this fact to the issuing bank of the credit card used by you as a customer. This process applies at any time even if you receive a confirmation email of the payment corresponding to the purchase order.<br/><br/>The mere fact of following the steps to make a purchase is equivalent to accepting that Ron Don Miguel has indeed complied with the conditions contained in this section.",
  paymentMethod: "Payment Method",
  paymentMethodDescription: "To make your purchase you can use any credit card authorized for online purchases. Online payments are processed by a platform that is responsible for online validation with the respective banks. The use of the cards identified previously will be subject to the provisions of these Terms and Conditions in relation to your issuing bank, and to what is agreed in the respective Opening Contracts and Regulations for the Use of Credit Cards. In case of contradiction, what is expressed in that last instrument will prevail. <br/><br/>In the case of the bank cards accepted on this site, the aspects related to these, such as the date of issue, expiration, quota, blocks, secret password, etc. will be governed by the respective User Agreement of each bank, in such a way that Ron Don Miguel will not have the responsibility in any of the aspects mentioned previously. <br/><br/>Refunds for any return will be made in exchange for a credit note, valid for 30 days.",
  limitsOfLiability: "Limits Of Liability",
  limitsOfLiabilityDescription: "This online page is provided by Ron Don Miguel SRL, as designed and subject to our availability. Ron Don Miguel SRL does not agree to guarantees of any kind, express or implicit, regarding the operation of the Ron Don Miguel page, the information offered, its content, materials, or products included on it. You agree to use this page under your sole responsibility. <br/><br/>Ron Don Miguel SRL does not guarantee that the www.rondonmiguel.com page, its servers, or its emails are free of any viruses of any other type of problems, and in no way is responsible for any type of direct, or indirect, incidentals derived from this situation.",
  websitePolicies: "Website Policies and its Modification",
  websitePoliciesDescription: "Please review our policies, such as prices and product availability, as well as the alternative purchase options published on this page. We reserve the right to make changes to our website, www.rondonmiguel.com, our policies, and these Terms of Use at any time. We recommend that all our clients review these terms and conditions frequently.",

};

export default messages;
