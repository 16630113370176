const messages = {
  shippingInformation: "Información",
  shippingInformationPreview: "Información de Envío",
  billingInformation: "Información de Facturación",
  foundUs: "Encuéntranos",
  foundUsMessages: "Actualmente no contamos con envíos disponibles hacia tú localidad, no obstante, aquí te frecemos nuestros puntos de ventas más cercanos.",
  resumen: "Resumen",
  personalInformation: "Información Personal",
  phone: "Número de contacto",
  addressShipping: "Dirección de Envío",
  address: "Dirección",
  province: "Provincia",
  municipality: "Municipio",
  postCode: "Código Postal",
  btnNext: "Siguiente",
  btnUpdate: "Actualizar",
  shippingMethods: "Métodos de Envío",
  billingMethods: "Métodos de Pago",
  btnUseAddress: "usar esta dirección",
  btnSave: "Guardar",
  btnChange: "Cambiar",
  btnPay: "Pagar"
};

export default messages;
