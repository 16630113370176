const alerts = {
  logInMessage: "Logeado!",
  errorMessage: "¡Hemos tenido un problema!",
  invalidCredentials: "Correo o contraseña incorrecta",
  tryLater: "Intente mas tarde",
  btnConfirm: "Aceptar",
  registerMessage: "Usuario registrado exitosamente",
  addProduct: "Agregar Producto",
  addProductMessage: "Producto Agregado!",
  saveOrder: "Guardar Orden",
  saveOrderMessage: "Orden Guardada Exitosamente, su factura ha sido enviada por correo.",
  addressUpdate: "Dirección actualizada",
  addressUpdateMessage: "Dirección actualizada exitosamente",
  addressCreate: "Dirección creada",
  addressCreateMessage: "Dirección creada exitosamente",
  soldOut: "Producto Agotado",
  soldOutMessage: "La cantidad ingresada no está disponible, por favor ingrese otra.",
  paymentInformation: 'Información de pago',
};
export default alerts;
