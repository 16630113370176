const programs = {
  programsRonDonMiguel: "Programas Ron Don Miguel",
  btnGetToKnowHim: "Conócelo",
  privateStockDescription: "Nuestro programa está dirigido a conocedores y amantes del buen ron, que deseen tener la oportunidad única de envejecer y embotellar su propio ron en barricas de 220 litros de roble americano ex–bourbon y <b><i><b> EXCLUSIVO A 6 CLIENTES AL AÑO.</b></i></b ><br /><br /> “RON DON MIGUEL PRIVATE STOCK®” te convierte en destilador por un día y en el propietario de una barrica completa de ron, la cual será posteriormente embotellada al cumplir como mínimo 5 años de envejecimiento.",
  howDoesItWork: "¿CÓMO FUNCIONA?",
  howDoesItWorkDescription: "<ul class='listul'><li>Coordinaremos una visita a destilería y ese día recibirás un tour con Julián Barceló Vallejo y Manuel Barceló Vargas, con quienes observarás todo el proceso de producción del alcohol a ser usado en tu barrica.</li><li class='py-5'>Podrás permanecer y participar todo el tiempo que desees en la destilería aprendiendo de las particularidades de la ciencia de producción de finos alcoholes. Podrás observar desde el proceso de recepción de caña, prensado, fermentación y posterior destilación de una manera muy dinámica y entretenida.</li><li> Posteriormente nos desplazaremos a los depósitos de envejecimiento y participarás del inicio del proceso. A tu barrica le pondremos tu nombre grabado y llenaremos con 170 litros del alcohol a 70% Alc./Vol. iniciando la magia de la transformación y envejecimiento. Igualmente la colocaremos en nuestro estante exclusivo de clientes Don Miguel PRIVATE STOCK®. </li><li class='py-5'>Mientras más tiempo envejece, más ron se evapora, producto del “Angel’s Share”, sin embargo obtendrás un producto final de calidad superlativa. Una barrica añejada por 5 años produce aproximadamente 340 botellas (+/- 10%) de 700ml a 37.5% Alc./Vol. </li><li>Podrás hacer una visita coordinada a tu barrica una vez al año, sacar una muestra y probar junto a 3 de tus familiares o amigos para explorar cómo va envejeciendo y transformándose tu ron.</li></ul>",
  whatYouWillReceive: "¿QUÉ RECIBIRÁS?",
  whatYouWillReceive1: "Aproximadamente 340 botellas de 700ml a 37.5% Alc./Vol. con la etiqueta personalizadas.",
  whatYouWillReceive2: "Dentro de cada botella colocaremos un OAK STICKS de 8 gramos de roble blanco americano tostado fuerte de la misma barrica que se destruirá para estos fines. Cada pieza será personalizada.",
  whatYouWillReceive3: "Una barrica de 4 litros personalizada conteniendo el mismo envejecido para que continúe el proceso en posesión del adquiriente.",
  documentCertified: "Documento certificado notarizado avalando la autenticidad de cada botella, emitido al adquiriente.",
  moreInformation: "Para mayor información contáctanos <b><b><a class='a' href='mailto:info@rondonmiguel.com'>info@rondonmiguel.com</a></b></b>",
  privateBarrelDescription: "Este programa está dirigido a conocedores y amantes del buen ron que deseen poseer su propia barrica activa y personalizada. Estas barricas, previamente “envinadas” con vino oloroso semi-dulce de Jerez el cual humedece la madera y le aporta esos sabores tan especiales a vainilla, higo y frutas maduras, son importadas desde España, elaboradas de roble blanco americano y con tostado medio plus, son llenadas en el lugar de entrega con ron a 38% Alc./Vol. y 36 meses netos de envejecimiento.<br /><br /> Nuestro programa ofrece barricas de 4, 8, 16, 32, 64, 128 y 200 litros que incluyen nuestros patentizados OAK STICKS. Estas piezas de roble blanco americano tostado fuerte con un peso de 8 gramos (+/- 1 gramo). Al ser introducidos en la barrica eficientizan y fortalecen el proceso natural de envejecimiento debido al contacto del ron con el roble.",
  dimensionAndWeight: "Dimensión y Peso",
  description: "Descripción",
  a: "A: Largo + grifo",
  b: "B: Diámetro de cabeza",
  c: "C: Diámetro de panza",
  d: "D:Altura de barril piesto en pie bajo",
  emptyWeight: "Peso vacías con base",
  filledWeight: "Peso llenas con base",
  frequentQuestions: "Preguntas Frecuentes",
  timeOfBarrel: "¿Qué tiempo de vida tiene la barrica?",
  timeOfBarrelDescription: "Es variable, sin embargo, si se mantiene en lugar fresco y constante a 25 grados Celsius y siempre lo más llena posible, puede mantenerse por más de 25 años.",
  refill: "¿Dónde y cómo se adquiere el re-fill?",
  refillDescription: "Directamente en la empresa en botellas de 1000 ml. del mismo envejecido de 36 meses netos (RON MADRE) con el que se entrega la barrica a 38% Alc./Vol.",
  maintenanceOfBarrel: "¿La barrica requiere algún mantenimiento?",
  maintenanceOfBarrelDescription: "El mantenimiento consiste en mantenerla siempre llena de ron.",
  howCanICareMyBarrel: "¿Qué debo de hacer para mantener mi barrica en buen estado?",
  howCanICareMyBarrelDescription: "Tenerla llena y en un lugar que no le de el sol y tenga una temperatura constante de 25ºC.",
  emptyMyBarrel: "¿Puedo vaciar completamente mi barrica ?",
  emptyMyBarrelDescription: "Si, se puede, sin embargo recuerda volver a llenarla lo antes posible. Lo ideal es que nunca esté completamente vacia.",
  whereIShouldHaveMyBarrel: "¿En qué lugar debo tener mi barrica ?",
  whereIShouldHaveMyBarrelDescription: " Lugar seco, sin luz solar directa y con temperatura constante de 25ºC.",
  deliveryTime: "Tiempo de Entrega",
  deliveryTimeDescription: "Generalmente tenemos inventario de barricas de todos los tamaños. El tiempo de entrega en Zona Metropolitana es de 5 días laborables. Para otras localidades 8 días laborables. En caso de no contar con el tamaño de su selección, el tiempo de entrega es de aproximadamente 90 – 120 días. Para mayor información contáctanos",
  moreInformation2: "Para mayor información contáctanos <b><b><a class='a' href='mailto:privateprogram@rondonmiguel.com'>privateprogram@rondonmiguel.com</a></b></b>",
};

export default programs;
