import ordersServices from "../../services/orders";

const orders = {
  state: () => ({
    orders: [],
    order: null,
  }),
  getters: {
    getOrders: (state) => state.orders,
    getOrder: (state) => state.order,
  },
  actions: {
    async fetchOrders({ commit }) {
      const { data: dataOrders } = await ordersServices.getOrder();
      commit('setOrders', dataOrders);
    },
    async fetchOrderById({ commit }, orderId) {
      const { data: dataOrder } = await ordersServices.getOrderById(orderId);
      if (dataOrder) {
        commit('setOrder', dataOrder.data);
      }
    },

  },
  mutations: {
    setOrders(state, data) {
      state.orders = data;
    },
    setOrder(state, data) {
      state.order = data;
    }
  },
  namespaced: true
};

export default orders;
