const messages = {
  title: "TÉRMINOS Y CONDICIONES",
  termsOfUse: "Condiciones de Uso",
  termsOfUseDescription: "Bienvenidos a Ron Don Miguel. Ron Don Miguel y sus afiliados proveen sus productos sujetos a las siguientes condiciones. Si visitas o compras en www.rondonmiguel.com , aceptas estas condiciones. Por favor, léelas de forma detenida y cuidadosa.",
  electronicCommunications: "Comunicaciones Electrónicas",
  electronicCommunicationsDescription: "Cuando visitas la página en línea de Ron Don Miguel o nos envías un correo electrónico, te estás comunicando con nosotros de forma electrónica. Con esta acción estas de acuerdo con mantener como medio de comunicación la forma electrónica.<br/><br/>Nos comunicaremos contigo vía correo o mediante avisos en nuestra página. Estás de acuerdo en que todos los acuerdos, responsabilidades de compra, notificaciones y cualquier otro tipo de comunicación que te enviemos electrónicamente satisfacen cualquier requerimiento legal que pudiese presentarse.",
  copyright: "Derechos de Autor",
  copyrightDescription: "Todo el contenido de esta página, textos, gráficos, logos, fotografías, botones, audio, descargas digitales, compilaciones de data, y software es propiedad de Ron Don Miguel SRL y está avalado en las leyes de derecho de autor de la República Dominicana.",
  licensesAndAccessToTheWebsite: "Licencias y Acceso a la Página de Internet",
  licensesAndAccessToTheWebsiteDescription: "Esta página en línea de Ron Don Miguel no podrá ser reproducida, duplicada, copiada, vendida o utilizada para ningún propósito comercial, sin un acuerdo por escrito con Ron Don Miguel SRL. Cualquier violación a las especificaciones anteriormente expuestas será procesada legalmente.",
  rightsOfUse: "Derechos de Uso de www.rondonmiguel.com",
  rightsOfUseDescription: "La sola visita de este sitio en el cual se ofrecen determinados productos, no te impone obligación alguna, a menos que hayas aceptado las condiciones ofrecidas por la empresa, en la forma indicada en estos términos y condiciones. www.rondonmiguel.com es para uso exclusivo de individuos que deseen revisar, considerar, comprar o participar en transacciones que involucren los productos de Ron Don Miguel a nombre propio (o, en el caso de un regalo, en nombre de sus destinatarios).",
  productInformation: "Información del Producto",
  productInformationDescription: "Productos exclusivos para la web estarán solo a la venta en www.rondonmiguel.com. En algunos casos, los productos que se muestran para la venta en los diferentes puntos de comercialización de Ron Don Miguel pueden no estar disponible en www.rondonmiguel.com .",
  photographs: "Fotografías",
  photographsDescription: "Ron Don Miguel exhibe las imágenes de productos con la mayor nitidez y precisión posible. No obstante, la visualización del color de los productos dependerá del monitor desde el que accedas a nuestra página en línea. Las fotos de productos presentados en el sitio son referenciales, tienen por finalidad brindarte una idea del producto, más no necesariamente referencias sobre el producto mismo por lo que pueden existir variantes entre la foto en www.rondonmiguel.com y el producto recibido.",
  inventory: "Inventario",
  inventoryDescription: "Ron Don Miguel se encarga de la actualización de la página y la revisión constante de los productos que se exhiben en el sitio, no obstante, Ron Don Miguel puede modificar y descontinuar los productos en cualquier momento sin contraer ninguna responsabilidad frente a ti como cliente.",
  inventoryRuleOne: "Los productos agotados se exhibirán en la web con una restricción de “Agotado”.",
  inventoryRuleTwo: "En virtud a ello, Ron Don Miguel se reserva el derecho de no aprobar la solicitud de compra y en consecuencia de no despachar un producto si este no cuenta con el inventario solicitado.",
  inventoryRuleThree: "En caso que no exista inventario del material elegido, automáticamente se genera un Gift Card (Certificado de Regalo).",
  priceAndPromotions: "Precios y Promociones",
  priceAndPromotionsDescription: "Los precios y promociones ofrecidos en www.rondonmiguel.com no son necesariamente los mismos que ofrecen otros canales de venta utilizados por Ron Don Miguel, tales como tiendas físicas, TV, catálogos u otros, a menos que se señale expresamente en este sitio o en la publicidad que realice Ron Don Miguel para cada promoción. <br/><br/>Los precios y/o promociones de los productos y servicios publicados en este sitio, se encuentran vigentes únicamente mientras aparezcan en él. Ron Don Miguel podrá modificar cualquier información contenida en este sitio.<br/><br/>Los precios regulares aparecen en NEGRO.<br/><br/>Los precios y/o promociones de los productos y servicios publicados en este sitio, se encuentran vigentes únicamente mientras aparezcan en él. Ron Don Miguel podrá modificar cualquier información contenida en este sitio.<br/><br/>Los precios de los productos ofrecidos en el sitio están expresados en dólares americanos, indicando la tasa actual en página, salvo que se manifieste lo contrario.<br/><br/>Los precios ofrecidos corresponden exclusivamente al valor del producto ofrecido y no incluyen gastos de transporte, manejo, envío, accesorios que no se describan expresamente ni ningún otro ítem adicional. De existir un error de digitación en alguno de los precios de los productos, si el precio correcto del artículo es más alto que el que figura en la página, Ron Don Miguel te contactará antes de que el producto sea enviado, y/o cancelaremos el pedido y te notificaremos acerca de la cancelación. En caso que el precio sea más bajo, se descontará la diferencia de la orden de compra final.",
  knowledgeOfTheOrders: "Conocimiento de los pedidos realizados a través de este sitio",
  knowledgeOfTheOrdersDescription1: "A través de www.rondonmiguel , realizarás la compra de productos utilizando los mecanismos que el mismo e-commerce ofrece para ello. Toda aceptación de oferta quedará sujeta a la condición suspensiva de que Ron Don Miguel valide la transacción. En consecuencia, para toda operación que se efectúe en la página en línea de Ron Don Miguel, la confirmación y/o validación o verificación por parte de Ron Don Miguel será requisito para la formación del consentimiento. Para validar la transacción Ron Don Miguel deberá verificar:",
  check1: "Que tus datos, registrados por ti en la página, coincidan con los proporcionados al efectuar tu aceptación de oferta.",
  check2: "Validación de información financiera.",
  check3: "Que el pago es acreditado por ti como el Usuario.",
  check4: "Que el pedido sea realizado por mayores de 18 años con cédula o pasaporte vigente.",
  knowledgeOfTheOrdersDescription2: "Te enviaremos una confirmación de tu pedido a través del correo electrónico que nos hayas brindado durante el momento de tu registro. El consentimiento se entenderá formado desde el momento en que se te envía esta confirmación escrita y en el lugar en que fue expedida. Si el proceso de compra no llegara a aprobarse, Ron Don Miguel enviará un correo electrónico al cliente para informarle que su orden ha sido cancelada y no se ha efectuado un pago. Como medida de seguridad antifraude, Ron Don Miguel y la plataforma de pago contratada, te podrán contactar para verificar la legalidad de la compra, de no ubicarlo Ron Don Miguel dejará sin efecto dicha transacción. En este caso la plataforma de pago comunicará este hecho al Banco emisor de la tarjeta de crédito utilizada por ti como cliente. Este proceso aplica en cualquier momento incluso si recibieras un correo con la confirmación de la orden de compra o confirmación del pago correspondiente a la orden de compra.<br/><br/>El sólo hecho de seguir los pasos para efectuar una compra, equivale a aceptar que efectivamente Ron Don Miguel ha dado cumplimiento a las condiciones contenidas en este apartado.",
  paymentMethod: "Métodos de Pago",
  paymentMethodDescription: "Para efectuar tu compra podrás utilizar cualquier tarjeta de crédito autorizada para compras por internet. Los pagos online son procesados por una plataforma que se encarga de realizar la validación en línea con los bancos respectivos. El uso de las tarjetas singularizadas anteriormente, se sujetará a lo establecido en estos Términos y Condiciones en relación con tu banco emisor, y a lo pactado en los respectivos Contratos de Apertura y Reglamento de Uso de Tarjetas de Crédito. En caso de contradicción, predominará lo expresado en ese último instrumento.<br/><br/>Tratándose de las tarjetas bancarias aceptadas en este sitio, los aspectos relativos a estas, tales como la fecha de emisión, caducidad, cupo, bloqueos, clave secreta, etc., se regirán por el respectivo Contrato de Uso de cada banco, de tal forma que Ron Don Miguel no tendrá responsabilidad en cualquiera de los aspectos señalados.<br/><br/>Los reembolsos por cualquier devolución se harán a cambio de una nota de crédito, con validez de 30 días.",
  limitsOfLiability: "Límites de Responsabilidad",
  limitsOfLiabilityDescription: "Esta página en línea la provee Ron Don Miguel SRL, tal y como ha sido diseñada y sujeta a nuestra disponibilidad. Ron Don Miguel SRL no acuerda garantías de ningún tipo, expresas o implícitas, sobre la operación de la página Ron Don Miguel, la información ofrecida en ella, su contenido, materiales o productos incluidos en el mismo. Tu acuerdas utilizar esta página bajo su absoluta responsabilidad.<br/><br/>Ron Don Miguel SRL no garantiza que la página www.rondonmiguel.com , sus servidores, o su correo electrónico estén libres de virus o cualquier otro tipo de problemas, y de ninguna forma se hace responsable de ningún tipo de problemas directos, indirectos, o incidentales derivados de esta situación.",
  websitePolicies: "Políticas de la Página de Internet y su Modificación",
  websitePoliciesDescription: "Por favor revisa nuestras políticas, tales como precios y disponibilidad de productos, así como las opciones de alternativas a la compra publicadas en esta página. Nos reservamos el derecho de realizar cambios a nuestra página www.rondonmiguel.com , nuestras políticas, y a estas Condiciones de Uso en cualquier momento. Le recomendamos a todos nuestros clientes revisar frecuentemente estos términos y condiciones.",

};

export default messages;
