const messages = {
  home: "Inicio",
  aboutUs: "Sobre Nosotros",
  foundUs: "Encuéntranos",
  contactUs: "Contáctanos",
  products: "Productos",
  programs: "Programas",
  myAccount: "Mi Cuenta",
  cart: "Carrito",
  logIn: "Iniciar Sesión",
  register: "Registrarse",
  logOut: "Cerrar Sesión",
  alcoholWarning: "El consumo excesivo de alcohol <br/>es perjudicial para la salud.",
  phraseDonMiguel: "“NUNCA HE PERDIDO LA FE EN EL DOMINICANO COMO PERSONA, EN LA DEMOCRACIA COMO SISTEMA Y EN LA REPUBLICA DOMINICANA COMO PAÍS”",
  termAndConditions: "Términos y Condiciones",
  privacyPolicies: "Políticas de Privacidad",
  contacUs: "Contáctanos",
  copyRight: "Derechos Reservados del Autor"
};
export default messages;
