import cartService from '../../services/shoppingCart';

// initial state
const shoppingCart = {
  state: () => ({
    cartDetails: [],
    productCart: null,
    shippingMethodsDetails: [],
    paymentMethodsDetails: [],
    orderDetails: []

  }),
  getters: {
    getCart: (state) => state.cartDetails,
    getShippingDetails: (state) => state.shippingMethodsDetails,
    getPaymentDetails: (state) => state.paymentMethodsDetails,
    getOrderDetails: (state) => state.orderDetails,
  },
  actions: {

    async addProductInCart({ commit }, productId) {
      const { data: productData } = await cartService.addProductToCart(productId);
      commit('setCartDetails', productData.data);
    },
    async fetchCartDetails({ commit }) {
      const { data: cartData } = await cartService.getCartDetails();
      commit('setCartDetails', cartData.data);
    },
    async updateProductInCart({ commit }, data) {
      const { data: cartData } = await cartService.updateProductToCart(data.cartItemId, data.qty);
      commit('setCartDetails', cartData.data);
    },
    async removeProductInCart({ commit }, productId) {
      const { data: productData } = await cartService.removeProduct(productId);
      commit('setProductCart', productData);
    },
    async saveAddressToCart({ commit }, addressCart) {
      const { data: addressData } = await cartService.saveAddressToCart(addressCart);
      commit('setAddressCart', addressData);
    },
    async saveShippingToCart({ commit }, shippingCart) {
      const { data: paymentData } = await cartService.saveShippingMethodToCart(shippingCart);
      commit('setPaymentCart', paymentData);
    },
    async savePaymentToCart({ commit }, paymentCart) {
      const { data: paymentData } = await cartService.savePaymentMethodToCart(paymentCart);
      commit('setOrderCart', paymentData);
    },
    async saveOrderDetails({ commit }) {
      const order = await cartService.saveOrder();
      commit('setOrderCart', order);
    },
    // eslint-disable-next-line no-unused-vars
    async payOrder({ commit }, orderId) {
      return cartService.payOrder(orderId);
    },
  },
  mutations: {
    setCartDetails(state, data) {
      state.cartDetails = data;
    },
    setProductCart(state, data) {
      state.productCart = data;
    },
    setAddressCart(state, data) {
      state.shippingMethodsDetails = data;
    },
    setPaymentCart(state, data) {
      state.paymentMethodsDetails = data;
    },
    setOrderCart(state, data) {
      state.orderDetails = data;
    }
  },
  namespaced: true
};

export default shoppingCart;
