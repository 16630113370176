import accountServices from "../../services/customer";

// initial state
const account = {
  state: () => ({
    user: null
  }),
  getters: {
    getUser: (state) => state.user
  },
  actions: {
    async registerUser({ commit }, data) {
      const { data: customerData } = await accountServices.register(data);
      commit("setUser", customerData);
    },
    async updateUser({ commit }, data) {
      const { data: customerData } = await accountServices.updateUser(data);
      commit("setUser", customerData);
    },
    async loginUser({ commit }, data) {
      const { data: customerLoginData } = await accountServices.login(data);
      commit("setUser", customerLoginData.data);
      localStorage.setItem("token", customerLoginData.token);
    },
    async fetchGetUser({ commit, dispatch }) {
      try {
        const { data: userData } = await accountServices.getUser();
        commit("setUser", userData.data);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch("removeLoggedInUser");
        } else {
          dispatch("logoutUser");
        }
        throw error;
      }
    },
    async removeLoggedInUser({ commit }) {
      commit("setlogoutUser");
      localStorage.removeItem("token");
    },

    async logoutUser({ dispatch }) {
      try {
        await accountServices.logout();
      } finally {
        dispatch("removeLoggedInUser");
      }
    },
    async resetPassword(email) {
      await accountServices.forgotPassword(email);
    }
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setlogoutUser(state) {
      state.user = "";
    }
  },
  namespaced: true
};

export default account;
