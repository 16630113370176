import axios from "axios";
import i18n from "../plugins/i18n";

const fetchClient = () => {
  const defaultOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    baseURL: process.env.VUE_APP_ECOMMERCE_API,
  };

  // Create instance
  const instance = axios.create(defaultOptions);
  // Set the AUTH token for any request
  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    // eslint-disable-next-line no-param-reassign
    config.url = config.url.lastIndexOf('?') > -1 ? `${config.url}&locale=${i18n.locale}` : config.url;
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return instance;
};

export default fetchClient();
