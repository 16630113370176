const order = {
  purchaseHistory: "Purchase History",
  orderNo: "Order no.",
  createdAt: "Date of purchase",
  shippingAddress: "Shipping Address",
  total: "Total",
  customer: "Customer",
  status: "Order Status",
  unitPrice: 'Unit Price',
  orderSummary: "Order summary",
  payOrder: 'Pay order',
};

export default order;
