import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import http from 'axios';
import moment from 'moment';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from "./plugins/i18n";

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_PUBLIC_KEY });
Vue.use(Vuelidate);
Vue.config.productionTip = false;
Vue.prototype.moment = moment;
window.axios = http;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
