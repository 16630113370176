import http from './axios';

export default {
  async sendEmailMessage(data) {
    try {
      return await http.post(`/api/contact/send-email`, data);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
};
