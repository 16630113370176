const messages = {
  btnAccess: "Access",
  forgotYourPassword: "Forgot your password?",
  dontHaveAnAccount: "Do not you have an account yet?",
  btnCreateAccount: "Create Account",
  verifiedPassword: "Confirm Password",
  haveAnAccount: "Do you already have an account?",
  returnToTheStore: "Return to the store",
  recoverPassword: "Recover password",
  recoverPasswordMessage: "Enter your password to reset your password",
  btnReset: "Reset",
  loading: 'Loading...',
};

export default messages;
