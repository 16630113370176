const order = {
  purchaseHistory: "Historial de Compra",
  orderNo: "Orden no.",
  createdAt: "Fecha de Compra",
  shippingAddress: "Dirección de Envío",
  total: "Total",
  customer: "Cliente",
  status: "Estado de la Orden",
  unitPrice: 'Precio Unitario',
  orderSummary: "Resumen de pedido",
  payOrder: 'Pagar orden',
};

export default order;
