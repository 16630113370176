/* eslint-disable consistent-return */
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/sobre-nosotros',
    name: 'about-us',
    component: () => import('../components/home/about-us2.vue'),
  },
  {
    path: '/terminos',
    name: 'terminos',
    component: () => import('../components/breadcrumb_footer/terminos-condiciones.vue'),
  },
  {
    path: '/politicas',
    name: 'policts',
    component: () => import('../components/breadcrumb_footer/politics.vue'),
  },
  {
    path: '/contactanos',
    name: 'contact-us',
    component: () => import('../components/breadcrumb_footer/contact-us.vue'),
  },
  {
    path: '/productos',
    name: 'collection-products',
    component: () => import('../components/products/collection-products.vue'),
  },
  {
    path: '/productos/:slug',
    name: 'product-detail',
    component: () => import('../components/products/product-detail.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../components/account/register.vue'),
  },
  {
    path: '/log-in',
    name: 'login',
    component: () => import('../components/account/login.vue'),
  },
  {
    path: '/reset-password',
    name: 'resetpassword',
    component: () => import('../components/account/resetpassword.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../components/account/profile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../components/checkout/cart-list.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/payment-info',
    name: 'payment-info',
    component: () => import('../components/checkout/payment-info.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/shipment-info',
    name: 'shipment-info',
    component: () => import('../components/account/shipment-info.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/orders',
    name: 'orders-history',
    component: () => import('../components/order/ordersHistory.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/orders/:order_id',
    name: 'order-detail',
    component: () => import('../components/order/orderDetails.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/programs',
    name: 'programs',
    component: () => import('../components/programs/programs.vue'),
  },
  {
    path: '/programs/private-stock',
    name: 'private-stock',
    component: () => import('../components/programs/privateStock.vue'),
  },
  {
    path: '/programs/private-barrel',
    name: 'private-barrel',
    component: () => import('../components/programs/privateBarrel.vue'),
  },
  {
    path: '/payment/azul/:status',
    component: () => import('../views/Payment.vue'),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    }

    if (!savedPosition) {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!localStorage.getItem('token')) {
      next({
        path: '/log-in',
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }

    if (to.name === 'login' && localStorage.getItem('token')) next({ name: 'home' })
    else next()
  
});

export default router;
