const messages = {
  title: "Carrito de Compras",
  subtitle: "Mi carrito / <b> {count} Artículo (s) </b>",
  item: "Artículos",
  price: "Precio",
  quantity: "Cant.",
  total: "Total",
  btnCart: "Eliminar",
  btnPay: "Pagar",
  btnGoToCart: "Ir al Carrito",
  taxesIncluded: "Impuestos incluidos"
};

export default messages;
