import productsService from '../../services/products';

// initial state
const products = {
  state: () => ({
    products: [],
    product: "",

  }),
  getters: {
    getProducts: (state) => state.products,
    getProduct: (state) => state.product,
  },
  actions: {
    async fetchGetProducts({ commit }) {
      const { data: productsData } = await productsService.getProducts();
      commit('setProducts', productsData);
    },
    async fetchGetProductBySlug({ commit, dispatch }, slug) {
      const { data: productData } = await productsService.getProductBySlug(slug);
      if (productData.data) {
        commit('setProduct', productData.data[0]);
        await dispatch('fetchGetProductAditionalInformation', productData.data[0].id);
      }
    },
    async fetchGetProductAditionalInformation({ commit }, id) {
      const { data: productAdditionalInfo } = await productsService.getProductAdditionalInfo(id);
      commit('setProductAdditionalInformation', productAdditionalInfo ? productAdditionalInfo.data : null);
    }
  },
  mutations: {
    setProducts(state, data) {
      state.products = data;
    },
    setProduct(state, data) {
      state.product = data;
    },
    setProductAdditionalInformation(state, data) {
      state.product = { ...state.product, additionalInformation: data };
    }
  },
  namespaced: true
};

export default products;
