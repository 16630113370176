import Vue from "vue";
import VueI18n from "vue-i18n";
import lang from "@/lang";

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem("lang") || "es",
  fallbackLocale: "es",
  messages: lang
});
