const messages = {
  title: "Products",
  generalDescriptionAboutProduct: 'The perfect combination of one of Don Miguel Barceló Pascual’s most precious formulas and the daring innovation of placing a piece of oak (American or French), with two types of toasts (medium plus or strong), inside the bottle. This causes the rum to continue its transformation as it was in the barrel. More than a rum of unsurpassed quality, it is a recognition of the legacy of the best Dominican rum maker in our history. <br/><br/> We invite you to be part of this tribute to Don Miguel Barceló Pascual! <br/><br/><span class="font-italic font-weight-bold "> Ron Don Miguel Homenaje</span>, Unprecedented! ',
  productDetails: "With this contribution, his son Julián and his grandchildren Manuel, Rodrigo and Julián Jr. They have the purpose of honoring and recognizing the legacy that was transmitted to them by their Father and Grandfather through these rums of unsurpassed quality. We invite you to be part of this tribute to Don Miguel Barceló Pascual.",
  btnKnowMore: "Know More",
  btnShowDetails: "Show Details",
  btnBuy: "Buy",
  unavailable: "Unavailable",
  details: "Details",
  tastingNotes: "Tasting Notes",
  phraseRum: "“World's first rum in <br class='d-none d-lg-flex' />incorporate <span class='josefin-italic'>infusion caps</span>,<br class='d-none d-lg-flex' />making each bottle a unique <br class='d-none d-lg-flex' />and unrepeatable experience”",
};
export default messages;
