const messages = {
  aboutTitle: "About Us",
  aboutDescription: "Our goal is to continue the legacy of Don Miguel Barceló Pascual, a visionary man recognized as the best Dominican rum creator in our history. He took Dominican rum to the highest quality peaks in the Dominican Republic, being a pioneer in the massive globalization of Dominican rum around the world. Proud of this legacy, we present to the market his most precious, personal formulas; products of the highest quality made for those consumers who appreciate good rum, experience the different and only settle for the exceptional.",
  btnKnowMore: "Know More",
  mission: "MISSION",
  missionDescription: '“Continue the legacy of Don Miguel Barceló Pascual, emphasizing his human, social, cultural, and business actions; offering innovative products of unsurpassed quality that satisfy the expectations of the most demanding consumers.”',
  view: "VISION",
  viewDescription: '“Satisfy the wishes and expectations of consumers, being a traditional and innovative company with the best selection of rums of unsurpassed quality. Anticipate the market by setting guidelines and becoming the most prestigious Dominican rum company.”',
  values: "VALUES",
  value1: "Family",
  value2: "Tradition",
  value3: "Pride",
  value4: "Altruism",
  value5: "Quality",
  value6: "Commitment",
  value7: "Excellence",
  value8: "Honestidad",
  value9: "Modesty",
  value10: "Integrity",
  value11: "Passion",
  theGreatestSenseOfQuality: "THE GREATEST SENSE OF QUALITY",
  theGreatestSenseOfQualityDescription: "Rigorous production standards that go beyond duty to achieve our superlative quality: the best rums in the world",
  whoIsADonMiguel: "WHO IS A DON MIGUEL?",
  whoIsADonMiguelDescription: "He is upright, noble, transparent, committed to his heart and mind, someone who seeks excellence in every action he develops. A responsible citizen who makes a difference by helping to build and support Dominican communities. Bold and innovative, always keeping in mind his principles, values, and traditions. He is altruistic, believing in the work of human beings and their capacity of growth and development.",
  ourShield: "OUR SHIELD",
  ourShieldDescription: "The Ron Don Miguel shield is made up of five parts that represent the essential elements to produce the best Dominican rums. Water, cane bud, alembic, our three pillars, and the Dominican flag are conjugated diagonally from the lower left to the upper left. Water represents life; the cane bud, where sugar comes from; the alembic, where our spirits come from. The three pillars that express our values: family, tradition, and pride. Finally, the Dominican flag that manifests the commitment to our country.",
  innovation: "Innovation",
  innovationDescription: "Ron Don Miguel Homenaje is the first rum in the world to incorporate infusion closures into its bottles. This innovation, applied to our products, follows a clear objective: to make each bottle a unique and unrepeatable experience. This has been achieved thanks to Oak Master ® infusion closures. These closures made from two types of oak (American or French) and two types of toasts (medium plus or strong), achieve what we record as “Transformation into bottle®.” When applied, they enhance the sensory and analytical characteristics of this already extraordinary rum, previously aged for 60 months. <br/><br/>The sensorial contribution of the closures begins as soon as they are introduced into the bottle by remaining in contact with the rum. They continue to yield the aromas and flavors of the oak, improving the rum’s characteristics. This is the contribution of the new generations to this history of quality.",

};

export default messages;
