const messages = {
  btnAccess: "Acceder",
  forgotYourPassword: "¿Olvidaste tu contraseña?",
  dontHaveAnAccount: "¿Aun no tienes una cuenta?",
  btnCreateAccount: "Crear Cuenta",
  verifiedPassword: "Confirmar Contraseña",
  haveAnAccount: "¿Ya tienes una cuenta?",
  returnToTheStore: "Volver a la tienda",
  recoverPassword: "Recuperar Contraseña",
  recoverPasswordMessage: "Ingrese su correo electrónico para restablecer su contraseña.",
  btnReset: "Restablecer",
  loading: 'Cargando...',
};

export default messages;
