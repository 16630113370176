const messages = {
  title: "My Account",
  personalInformation: "personal information",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  password: "Password",
  passwordText: "Change your password",
  oldPassword: "Old Password",
  newPassword: "New Password",
  verifiedNewPassword: "Verified New Password",
  messageText: "When updating your password you will receive a confirmation message to your email address.",
  navigationList: "Navigation List",
  shippingInformation: "Shipping Information",
  purchaseHistory: "Purchase History",
  logOut: "Log Out",
  btnUpdate: "Update"

};

export default messages;
