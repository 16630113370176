import http from "./axios";

export default {
  async addProductToCart(productId) {
    try {
      return await http.post(`/api/checkout/cart/add/${productId}?token=true`, {
        quantity: 1,
        product_id: productId
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async updateProductToCart(cartItemId, qty) {
    try {
      return await http.put(`/api/checkout/cart/update?token=true`, {
        qty: {
          [cartItemId]: qty
        }
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async getCartDetails() {
    try {
      return await http.get(`/api/checkout/cart?token=true`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async removeProduct(id) {
    try {
      return await http.get(`/api/checkout/cart/remove-item/${id}?token=true`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async saveAddressToCart(addressCart) {
    try {
      const { data } = await http.post(`/api/checkout/save-address?token=true`, addressCart);
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async saveShippingMethodToCart(shippingMethodCart) {
    try {
      const { data } = await http.post(`/api/checkout/save-shipping?token=true`, {
        shipping_method: shippingMethodCart
      });
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async savePaymentMethodToCart(paymentMethodCart) {
    try {
      const { data } = await http.post(`/api/checkout/save-payment?token=true`, {
        payment: {
          method: paymentMethodCart
        }
      });
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async saveOrder() {
    try {
      const { data } = await http.post(`/api/checkout/save-order?token=true`);
      return data.order;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async payOrder(orderId) {
    try {
      const { data } = await http.post(`/api/checkout/pay-order?token=true`, {
        order_id: orderId,
      });
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
};
