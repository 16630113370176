const locations = {
  state: () => ({
    city: "Santo Domingo",
    local: 1,
    location: {
      address: "Av. John F. Kennedy esq. Juan Mejía Cotes Arroyo Hondo, Santo Domingo"
    },
    cities: [],
    locations: {},
    loading: false,
    elevation: 0,
    provinces: [
      {
        name: "Azúa",
        region: "Región Suroeste"
      },
      {
        name: "Baoruco",
        region: "Región Suroeste"
      },
      {
        name: "Barahona",
        region: "Región Suroeste"
      },
      {
        name: "Dajabón",
        region: "Región Norte"
      },
      {
        name: "Duarte",
        region: "Región Norte"
      },
      {
        name: "Elías Pina",
        region: "Región Suroeste"
      },
      {
        name: "El Seibo",
        region: "Región Sureste"
      },
      {
        name: "Espaillat",
        region: "Región Norte"
      },
      {
        name: "Hato Mayor",
        region: "Región Sureste"
      },
      {
        name: "Independencia",
        region: "Región Suroeste"
      },
      {
        name: "La Altagracia",
        region: "Región Sureste"
      },
      {
        name: "La Romana",
        region: "Región Sureste"
      },
      {
        name: "La Vega",
        region: "Región Norte"
      },
      {
        name: "Maria Trinidad Sanchez",
        region: "Región Norte"
      },
      {
        name: "Monseñor Nouel",
        region: "Región Norte"
      },
      {
        name: "Monte Cristi",
        region: "Región Norte"
      },
      {
        name: "Monte Plata",
        region: "Región Sureste"
      },
      {
        name: "Pedernales",
        region: "Región Suroeste"
      },
      {
        name: "Peravia",
        region: "Región Suroeste"
      },
      {
        name: "Puerto Plata",
        region: "Región Norte"
      },
      {
        name: "Salcedo",
        region: "Región Norte"
      },
      {
        name: "Samana",
        region: "Región Norte"
      },
      {
        name: "Sánchez Ramírez",
        region: "Región Norte"
      },
      {
        name: "San Cristobal",
        region: "Región Suroeste"
      },
      {
        name: "San Jose de Ocoa",
        region: "Región Suroeste"
      },
      {
        name: "San Juan",
        region: "Región Suroeste"
      },
      {
        name: "San Pedro de Macorís",
        region: "Región Sureste"
      },
      {
        name: "Santiago",
        region: "Región Norte"
      },
      {
        name: "Santiago Rodríguez",
        region: "Región Norte"
      },
      {
        name: "Santo Domingo",
        region: "Santo Domingo"
      },
      {
        name: "Valverde",
        region: "Región Norte"
      },
    ]
  }),
  getters: {
    getLocations: (state) => state.locations,
    getLocal: (state) => state.local,
    getLocation: (state) => state.location,
    getCities: (state) => state.cities,
    getCity: (state) => state.city,
    getProvinces: (state) => state.provinces,

  },
  actions: {
    initInfo({ commit }) {
      commit('initInfo');
    },
    getIdLocation({ commit }, data) {
      commit('setIdLocation', data);
    }
  },
  mutations: {
    initInfo(state) {
      state.cities = [
        { name: "Santo Domingo", id: 1 },
        { name: "Región Norte", id: 2 },
        { name: "Región Sureste", id: 3 }
      ];

      state.locations = {
        "Santo Domingo": [
          {
            text: "Licormart",
            address: "Av. John F. Kennedy esq. Juan Mejía Cotes Arroyo Hondo, Santo Domingo"
          },
          {
            text: "Licormart",
            address: "Av Sarasota 106, Santo Domingo"
          },
          {
            text: "Licormart",
            address: "Av Gustavo Mejía Ricart No. 84, Piantini, Santo Domingo"
          },
          {
            text: "Marche",
            address: "Cuesta Hermosa Town Center. Arroyo Hondo. Santo Domingo"
          },
          {
            text: "La Licorera",
            address: "Núñez de Cáceres #567. El Millón. Santo Domingo"
          },
          {
            text: "City Market",
            address: " Federico Gerardino,83. Plaza Mezzaluna"
          },
          {
            text: "Multibebidas / perez Quezada Group SRL",
            address: "Manuel Machado No.58, Villa Consuelo"
          },
          {
            text: "Almacenes Hatuey",
            address: "Ave. Romulo Betancourt #2, Bella Vista"
          },
          {
            text: "Albert’s Licores",
            address: "Av. José Contreras 184, Santo Domingo"
          },
          {
            text: "Almacenes America",
            address: "Av. Gustavo Mejía Ricart 108"
          },
          {
            text: "Home Liquor: Boca Chica",
            address: "Autopista Las Americas esq. Calle Mella"
          },
          {
            text: "Plaza Lama: 27 de Febrero",
            address: "Av. 27 de Febrero esq Av Churchill"
          },
          {
            text: "Ron Depot: Naco y Urb. Real",
            address: "Av. 27 de Febrero. Casi esq, Avenida Ortega y Gasset #199"
          },
          {
            text: "Ron Depot: Naco y Urb. Real",
            address: "Av 27 de Febrero y Av Caonabo"
          },
          {
            text: "Drinks 2 Go",
            address: "Av. Tiradentes"
          },
          {
            text: "Drinks 2 Go",
            address: "Av. Winston Churchill, Plaza New Orleans, Urb. Fernandez"
          },
          {
            text: "Drinks 2 Go",
            address: "Av. Núñez de Cáceres 115"
          },
          {
            text: "Drinks 2 Go",
            address: "Av. Máximo Gómez Esq Juan Sánchez Ramírez."
          },
          {
            text: "Drinks 2 Go",
            address: "Av. Independencia, Esq. 2da, Jardines del Mirador km 7 1/2"
          },
          {
            text: "Almacenes Garrido",
            address: "Almacenes Garrido, Av. Juan Pablo Duarte"
          },
          {
            text: "Almacenes Garrido",
            address: "Almacenes Garrido, Las Américas"
          },
          {
            text: "Supermercado Hola!",
            address: "Supermercado Hola, Av. Núñez de Cáceres"
          },
          {
            text: "Supermercado Hola!",
            address: "Supermercado Hola, Av. 27 de Febrero"
          },
          {
            text: "Supermercado Hola!",
            address: "Supermercado Hola, Autopista Las Américas"
          },
          {
            text: "Supermercado El Dragón de Oro",
            address: "Supermercado El Dragón de Oro, Av. Rómulo Betancourt #1351"
          },
          {
            text: "Drinkcon",
            address: "Av. República de Colombia #21s"
          },
          {
            text: "La Casa de la Caña",
            address: "La Casa de la Caña, Av. Rómulo Betancourt, Bella Vista"
          },
          {
            text: "La Casa de la Caña",
            address: "La Casa de la Caña, Av. Gustavo Mejia Ricart, Los Prados"
          },
          {
            text: "La Casa de la Caña",
            address: "La Casa de la Caña, Av Enriquillo, Los Cacicazgos"
          },
          {
            text: "La Casa de la Caña",
            address: "La Casa de la Caña, Camino Chiquito"
          },
          {
            text: "La Casa de la Caña",
            address: "La Casa de la Caña, Cuesta Hermosa"
          },
          {
            text: "La Casa de la Caña",
            address: "La Casa de la Caña, Av Lincoln esq Jose Amado Soler"
          },
          {
            text: "La Casa de la Caña",
            address: "La Casa de la Caña, Av Venezuela"
          },
          {
            text: "La Casa de la Caña",
            address: "La Casa de la Caña, Autopista San Isidro"
          },
        ],
        "Región Norte": [
          {
            text: "Trapiche Liquor Store",
            address: "Trapiche Liquor Store, Santiago"
          },
          {
            text: "Vocatus",
            address: "Vocatus, La Vega"
          },
          {
            text: "Star Liquor Store",
            address: "Star Liquor Store, San Francisco de Macoris"
          },
          {
            text: "Licormart",
            address: "Licormart, Puerto Plata"
          },
          {
            text: "Meraki",
            address: "Meraki, Calle Independencia #49, Moca"
          },
        ],
        "Región Sureste": [
          {
            text: "Keep Brands",
            address: "Keep Brands, Higuey"
          },
          {
            text: "La Hoz",
            address: "La Hoz, La Romana"
          },
          {
            text: "Carvis",
            address: "Carvis, La Romana"
          },
          {
            text: "Licormart",
            address: "Licormart, Bavaro"
          },
        ]
      };
    },
    setIdLocation(state, item) {
      state.local = item.id;
      state.location.address = item.address;
    }
  },
  namespaced: true
};

export default locations;
