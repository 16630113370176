const alerts = {
  logInMessage: "Logged!",
  invalidCredentials: "Wrong email or password",
  errorMessage: "We have had a problem!",
  tryLater: "Try again later",
  btnConfirm: "Confirm",
  registerMessage: "Successfully registered user",
  addProductMessage: "Product added!",
  saveOrder: "Save Order",
  saveOrderMessage: "Order Successfully Saved, your invoice has been sent by mail.",
  addressUpdate: "Address Updated",
  addressUpdateMessage: "Address updated successfully",
  addressCreate: "Address created",
  addressCreateMessage: "Address created successfully",
  soldOut: "Sold Out",
  soldOutMessage: "The amount entered is not available, please enter another.",
  paymentInformation: 'Payment information',
};
export default alerts;
