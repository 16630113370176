const messages = {
  title: "Productos",
  generalDescriptionAboutProduct: 'Es la perfecta combinación de una de las fórmulas personales más preciadas de Don Miguel Barceló Pascual, reconocido como el mejor ronero dominicano de nuestra historia y de la atrevida innovación de colocar una pieza de roble (Americano o francés), con dos tipos de tostado (Medio plus o fuerte) dentro de la botella, logrando que el ron continúe tal cual estuviera en barrica, llamando a este proceso TRANSFORMACIÓN EN BOTELLA®️. <br/><br/> Con este aporte su hijo Julián y sus nietos Manuel, Rodrigo y Julián Jr. Tienen la finalidad de honrar y reconocer el legado que le fue transmitido por su Padre y Abuelo a través de estos rones de insuperable calidad. Te invitamos a ser parte de este homenaje a Don Miguel Barceló Pascual.<br/><br/><span class="font-italic font-weight-bold">Ron Don Miguel Homenaje</span>, ¡Sin Precedentes!',
  productDetails: "Con este aporte su hijo Julián y sus nietos Manuel, Rodrigo y Julián Jr. Tienen la finalidad de honrar y reconocer el legado que le fue transmitido por su Padre y Abuelo a través de estos rones de insuperable calidad. Te invitamos a ser parte de este homenaje a Don Miguel Barceló Pascual.",
  btnKnowMore: "Conoce Más",
  btnShowDetails: "Ver Detalles",
  btnBuy: "Comprar",
  unavailable: "Agotado",
  details: "Detalles",
  tastingNotes: "Notas de la Cata",
  phraseRum: "“Primer ron del mundo en <br class='d-none d-lg-flex' />incorporar <span class='josefin-italic'>tapones de infusión,</span><br class='d-none d-lg-flex' />logrando que cada botella sea<br class='d-none d-lg-flex' />una experiencia única e irrepetible”"
};
export default messages;
