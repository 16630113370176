import http from "./axios";

export default {

  async getLocale() {
    try {
      return await http.get(`/api/locales?pagination=0`);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
};
