import http from './axios';
import i18n from "@/plugins/i18n";

export default {
  async getProducts() {
    try {
      return await http.get(`/api/products?order=asc&sort=created_at`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },

  async getProductBySlug(slug) {
    try {
      return await http.get(`/api/products?url_key=${slug}`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async getProductAdditionalInfo(id) {
    try {
      return await http.get(`/api/product-additional-information/${id}?locale=${i18n.locale}`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
};
