const messages = {
  title: "Contact Us",
  weWantToKnowYou: "WE WANT TO KNOW YOU",
  knowMore: "Write to us if you want to know additional information about our products.",
  ourTeam: "Would you like to join our team?",
  ourTeamDescription: "To be part of our team, you only have to download the job application form, complete all the required information, and send it to <a href='mailto:empleos@rondonmiguel.com'>empleos@rondonmiguel.com</a>. If your profile fits within any of our available vacancies, a representative of the recruitment team will contact you. ",
  btnDownloadForm: "Download Form",
  phone: "Phone",
  email: "Email",
  office: "Office",
  name: "Name",
  message: "Message",
  btnSend: "Send",
  infoProtected: "This site is protected by reCAPTCHA, google's <a href='https://policies.google.com/privacy'> privacy policies</a> and <a href='https://policies.google.com/terms'>the Terms of Services</a>",
  findUsTitle: "Find Us",
  findUsDescription: "find our authorized sales locales",
};

export default messages;
