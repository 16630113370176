import addressServices from "../../services/addresses";

// initial state
const addresses = {
  state: () => ({
    addresses: [],
    addressDefault: null
  }),
  getters: {
    getAddresses: (state) => state.addresses,
    getAddressDefault: (state) => state.addressDefault
  },
  actions: {
    async fetchCreateAddress({ commit, getters }, data) {
      if (!getters.getAddressDefault) {
        const { data: AddressData } = await addressServices.createAddress(data);
        commit("setAddress", AddressData.data);
      } else {
        const { data: AddressData } = await
        addressServices.updateAddress(getters.getAddressDefault.id, data);
        commit("setAddress", AddressData.data);
      }
    },
    async fetchGetAddresses({ commit }) {
      const { data: addressesData } = await addressServices.getAddresses();
      if (addressesData.data) {
        const defaultAddress = addressesData.data.length === 1
          ? addressesData.data[0]
          : addressesData.data.find((address) => address.is_default);
        commit('setAddressDefault', defaultAddress || addressesData.data[0]);
      }
    },
  },
  mutations: {
    setAddress(state, data) {
      state.addresses = data;
    },
    setAddresses(state, data) {
      state.addresses = data;
    },
    setAddressDefault(state, data) {
      state.addressDefault = data;
    },
  },
  namespaced: true
};

export default addresses;
