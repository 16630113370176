import http from './axios';

export default {

  async register(user) {
    try {
      return await http.post(`/api/customer/register`, user);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async updateUser(user) {
    try {
      return await http.put(`/api/customer/profile?token=true`, user);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async login(user) {
    try {
      return await http.post(`/api/customer/login?token=true`, user);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async getUser() {
    try {
      return await http.get(`/api/customer/get?token=true`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async logout() {
    try {
      return await http.get(`/api/customer/logout?token=true`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async forgotPassword(email) {
    try {
      return await http.post(`/api/customer/forgot-password?${email}`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
};
