<template>
  <v-container class="py-lg-10 dark">
    <section>
      <v-row class="d-flex d-lg-none pa-3 align-center">
        <v-col cols="7" class="pb-0">
          <a href="/">
            <v-img auto src="@/assets/favicon.svg" class="logo-menu-mobile mr-5" />
          </a>
        </v-col>
        <v-col cols="5" class="justify-end d-flex ma-0 pa-0 pr-5">
          <v-row>
            <v-col cols="4" sm="2" class="d-flex align-center ">
              <v-btn :disabled="!getCart" to="/cart" class="px-5 ml-n5 elevation-0 btn-cart">
                <v-img class="cart-icon mt-1" width="10" contain src="/icon/cart.svg" />
              </v-btn>
              <div class="red text-center px-1 mt-n6 ml-n5" v-if="getCart">
                {{ getCart.items_count }}
              </div>
            </v-col>
            <v-col cols="4" sm="2" class="d-flex align-center justify-center ma-0 pa-0">
              <v-app-bar color="transparent" dark prominent class="justify-center d-flex">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
              </v-app-bar>
            </v-col>
            <v-col cols="4" sm="2" class="d-flex align-center justify-center ma-0 pa-0">
              <v-select
                v-model="country"
                :items="countries"
                class="select_language mt-4 mr-4 align-self-center pa-0"
              >
                <template slot="selection" slot-scope="{ item }">
                  <span class="flag_container"> <country-flag :country="item.flag"/></span>
                </template>
                <template slot="item" slot-scope="{ item }">
                  <a href="#" @click="setLocale(item.locale, item.flag)">
                    <span class="flag_container_list"> <country-flag :country="item.flag"/></span>
                  </a>
                </template> </v-select
            ></v-col>
            <v-navigation-drawer v-model="drawer" absolute width="100%" class="menu-mobile">
              <v-list class="grow">
                <v-list-item-group active-class="">
                  <v-list-item
                    v-for="link in links"
                    :key="link.title"
                    link
                    class="menu_first mt-5"
                    :to="link.to"
                  >
                    <v-list-item-title
                      class="copperplate menu_items white-black my-5"
                      v-text="$t(link.title)"
                    >
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="!getUser" to="/log-in" class="menu_first">
                    <v-list-item-title class="copperplate menu_items white-black my-8">
                      {{ $t("menu.logIn") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="!getUser" to="/register" class="menu_first">
                    <v-list-item-title class="copperplate menu_items white-black">
                      {{ $t("menu.register") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="getUser" to="/profile" class="menu_first">
                    <v-list-item-title class="copperplate menu_items white-black my-8">
                      {{ $t("menu.myAccount") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="getUser" @click="logoutUser()" class="menu_first">
                    <v-list-item-title class="copperplate menu_items white-black">
                      {{ $t("menu.logOut") }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-navigation-drawer>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="px-10 ma-0 d-lg-flex d-none " justify="center">
        <v-col cols="12" class="ma-0">
          <a href="/">
            <v-img src="@/assets/logo1.svg" contain class="rounded-0 logo_side_bar mx-auto"></v-img>
          </a>
        </v-col>
      </v-row>
      <v-row class="my-7  d-sm-flex d-none">
        <v-navigation-drawer width="100%" class="nav-bar">
          <v-list class="grow">
            <v-list-item-group active-class="">
              <v-list-item v-for="link in links" :key="link.title" link class="menu_first my-7">
                <a :href="link.to">
                  <v-list-item-title
                    class="copperplate menu_items white-black my-5"
                    v-text="$t(link.title)"
                  >
                  </v-list-item-title>
                </a>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-row>
      <v-row justify="center" class="mt-0 px-10 mb-0  d-lg-flex d-none">
        <v-img src="@/assets/nav_bar_line.svg" contain class="my-3" max-width="85%"></v-img>
      </v-row>
      <v-row justify="center" class="my-10  d-lg-flex d-none">
        <v-col cols="2">
          <a href="https://www.instagram.com/rondonmiguelrd/" target="_blank">
            <v-img src="/icon/instagram.svg" class="ma-auto" height="19px" width="19px"></v-img>
          </a>
        </v-col>
        <v-col cols="2">
          <a href="https://www.facebook.com/rondonmiguelrd" target="_blank">
            <v-img
              src="/icon/facebook.svg"
              class="ma-auto"
              contain
              height="19px"
              width="19px"
            ></v-img>
          </a>
        </v-col>
        <v-col cols="2">
          <a href="https://twitter.com/RonDonMiguelrd" target="_blank">
            <v-img
              src="/icon/twitter.svg"
              class="ma-auto"
              contain
              height="19px"
              width="19px"
            ></v-img>
          </a>
        </v-col>
        <v-col cols="2">
          <a href="https://do.linkedin.com/company/rondonmiguelrd" target="_blank">
            <v-img
              src="/icon/linkedin.svg"
              class="ma-auto"
              contain
              height="23px"
              width="23px"
            ></v-img>
          </a>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-0 px-10 mb-0  d-lg-flex d-none">
        <v-img src="@/assets/nav_bar_line.svg" contain class="my-3" max-width="85%"></v-img>
      </v-row>
      <v-row justify="center" class="mt-10 d-lg-flex d-none">
        <p
          class="josefin-light-italic white-black text-center ley42_01"
          v-html="$t('menu.alcoholWarning')"
        ></p>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CountryFlag from "vue-country-flag";

export default {
  components: {
    CountryFlag
  },
  created() {
    this.fetchCartDetails();
  },
  data() {
    return {
      drawer: false,
      group: null,
      links: [
        {
          title: "menu.home",
          to: "/"
        },
        {
          title: "menu.aboutUs",
          to: "/sobre-nosotros"
        },
        {
          title: "menu.products",
          to: "/productos"
        },
        {
          title: "menu.foundUs",
          to: "/#location"
        },
        {
          title: "menu.programs",
          to: "/programs"
        }
      ],
      mini: true,
      country: {
        flag: localStorage.getItem("flag") || "do",
        locale: localStorage.getItem("lang") || "es"
      },
      countries: [
        {
          flag: "us",
          locale: "en"
        },
        {
          flag: "do",
          locale: "es"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("customer", ["getUser"]),
    ...mapGetters("shoppingCart", ["getCart"])
  },
  methods: {
    ...mapActions("customer", ["logoutUser"]),
    ...mapActions("shoppingCart", ["fetchCartDetails"]),
    setLocale(locale, flag) {
      localStorage.setItem("lang", locale);
      localStorage.setItem("flag", flag);
      this.$router.go();
    }
  },
  watch: {
    group() {
      this.drawer = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.select_language {
  max-width: 35px;
  height: 40px;

  .v-input__append-inner {
    display: none !important;
  }
  .v-input__slot:before {
    border-color: transparent !important;
  }
  .v-input__slot:after {
    display: none !important;
  }
  span {
    border-color: transparent !important;
  }
}
span.flag_container {
  z-index: 1;
  max-width: 50px !important;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  span {
    width: 50px;
  }
}
a.px-5.ml-n5.elevation-0.btn-cart.v-btn.v-btn--disabled.v-btn--has-bg.v-btn--router.theme--light.v-size--default,
a.px-5.ml-n5.elevation-0.btn-cart.v-btn.v-btn--is-elevated.v-btn--has-bg.v-btn--router.theme--light.v-size--default{
  background: transparent !important;
}
</style>
