import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';
import products from "./modules/products";
import contacts from "./modules/contacts";
import customer from "./modules/customer";
import addresses from "./modules/addresses";
import shoppingCart from "./modules/shoppingCart";
import locations from "./modules/locations";
import localeServices from "../services/locale";
import orders from "./modules/orders";
import programs from "./modules/programs";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    info: {
      birthday: {
        day: '',
        month: '',
        year: '',
      },
      country: '',
      language: '',
    },
    countries: ['RD', 'US', 'CO'],
    locales: null,

  },
  getters: {
    birthdaytring(state) {
      return `${state.info.birthday.year}-${state.info.birthday.month}-${state.info.birthday.day}`;
    },
    isLegal(state, getters) {
      return moment().diff(getters.birthdaytring, 'year') >= 18;
    },
    getLocale: (state) => state.locales,
  },
  mutations: {
    saveInfo(state, data) {
      state.info = data;
    },
    setLocale(state, data) {
      state.locales = data;
    }

  },
  actions: {
    saveInfo({ commit }, data) {
      commit('saveInfo', data);
      localStorage.setItem('info', JSON.stringify(data));
    },
    async fetchGetLocale({ commit }) {
      const { data: localeData } = await localeServices.getLocale();
      commit('setLocale', localeData);
    }
  },
  modules: {
    products,
    contacts,
    customer,
    addresses,
    shoppingCart,
    locations,
    orders,
    programs
  },
});
