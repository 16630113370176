import http from "./axios";

export default {
  async getOrder() {
    try {
      return await http.get(`/api/orders?token=true`);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getOrderById(id) {
    try {
      return await http.get(`/api/orders/${id}?token=true`);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
};
