<template>
  <age-filter-container v-if="!$store.getters.isLegal" />
  <v-app v-else>
    <v-main>
      <v-col class="colum1-mobile d-flex d-lg-none pa-0 ">
        <side-bar />
      </v-col>
      <v-col class="colum1 d-none d-lg-flex pa-0 fixed fill-height">
        <side-bar />
      </v-col>
      <v-col class="colum2 pa-0">
        <router-view />
        <footer-first />
      </v-col>
    </v-main>
  </v-app>
</template>

<script>
import SideBar from "@/components/home/sidebar.vue";
import FooterFirst from "@/components/footer/footer.vue";
import AgeFilterContainer from "@/components/welcome/age-filter-container.vue";
import { mapActions } from "vuex";

export default {
  name: "App",

  components: {
    SideBar,
    FooterFirst,
    AgeFilterContainer,
  },
  methods: {
    ...mapActions("customer", ["fetchGetUser"]),
    ...mapActions("addresses", ["fetchGetAddresses"]),
    ...mapActions(["fetchGetLocale"])
  },
  async created() {
    if (localStorage.getItem('token')) {
      this.fetchGetUser().then(async () => {
        await Promise.all([this.fetchGetAddresses()]);
      });
    }
    this.fetchGetLocale();
  }
};
</script>

<style lang="scss">
@import "@/styles/index.scss";
</style>
