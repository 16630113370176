const programs = {
  programsRonDonMiguel: "Ron Don Miguel Programs",
  btnGetToKnowHim: "get to know it",
  privateStockDescription: "Our program is directed to connoisseurs and lovers of good rum that wish to have the unique opportunity of aging and bottling their own rum in 220 liter ex-bourbon barrels made of American Oak.<b><i> EXCLUSIVE TO 6 CUSTOMERS PER YEAR.</i></b ><br /><br /> “RON DON MIGUEL PRIVATE STOCK®” program makes you a distiller for a day and the owner of a complete barrel of rum, which will be bottled once it has aged for at least 5 years. ",
  howDoesItWork: "How does it work?",
  howDoesItWorkDescription: "<ul class='listul'><li>We will coordinate a visit to the distillery and you will receive a tour with Julián Barceló Vallejo, with whom you will observe the whole production process of the alcohol that is going to be used in your barrel.</li><li class='py-5'>You will be able to stay in the distillery and participate as long as you want learning about the particularities of the science of producing fine alcohols. You will be able to observe the process of the cane reception, pressing, fermentation, and subsequent distillation in a very dynamic and entertaining manner.</li><li>Afterwards we will move to the aging tanks and you will participate in the beginning of the process. We will engrave your name on your barrel and fill it with 170 liters of alcohol at 70% Alc./Vol. initiating the magic of transformation and aging. We will also place it on our exclusive Don Miguel PRIVATE STOCK® customer shelf.</li><li class='py-5'>The longer it ages, more rum evaporates product of “Angel’s Share”. However, you will obtain a final product of superlative quality. A barrel aged for 5 years produces approximately 340 bottles (+/- 10%) of 700ml at 37.5% Alc./Vol.</li><li>You can coordinate a visit to your barrel once a year, take a sample and taste it with 3 of your friends and family to explore how the rum is aging and transforming.</li></ul>",
  whatYouWillReceive: "What will you receive?",
  whatYouWillReceive1: "Approximately 340 bottles (+/- 10%) of 700ml at 37.5% Alc./Vol. with custom labels (see references).",
  whatYouWillReceive2: "Inside each bottle there will be 8-gram OAK STICKS   made of white American Oak with strong toast, made of the same barrel which be destroyed for this purpose. Each piece will be personalized (see references).",
  whatYouWillReceive3: "A custom 4-liter barrel containing the same aged rum, so the process can continue in the possession of the purchaser.",
  documentCertified: "Notarized certified document endorsing the authenticity of each bottle, issued to the purchaser.",
  moreInformation: "For more information, contact us <b><b><a class='a' href='mailto:info@rondonmiguel.com'>info@rondonmiguel.com</a></b></b>",
  privateBarrelDescription: "This program is directed connoisseurs and lovers of good rum that wish own their own active and personalized barrel. The barrels are previously “encased” with fragrant semi-sweet sherry wine, which moistens the wood and gives it those special vanilla, fig, and ripe fruit flavors. These are imported from Spain, made of white American oak with medium plus toast, and filled at the place of delivery with rum at 37.5% Alc/Vol. with 36 net months of aging.<br /><br /> Our program offers 4, 8, 16, 32, 64, 128, and 200 litter barrels that include our patented OAK STICKS, made of white American oak with strong toast and weight 8 grams (+/- gram). When they are introduced into the barrel, they strengthen the natural aging process and make it more efficient due to the contact of the rum with the oak. ",
  dimensionAndWeight: "WEIGHT AND DIMENSIONS",
  description: "DESCRIPTION",
  a: "A: Length + tap",
  b: "B: Head diameter",
  c: "C: Belly diameter",
  d: "D: Height of the barrel with base",
  emptyWeight: "Empty weight with base",
  filledWeight: "Filled weight with base",
  frequentQuestions: "FAQ",
  timeOfBarrel: "How long does the barrel last?",
  timeOfBarrelDescription: "It may vary, however, if it is kept as full as possible in a cool place, constant to 25 degrees Celsius, it can last more than 25 years. ",
  refill: "Where and how is the re-fill acquired?",
  refillDescription: "Directly at the company in 1000 ml. bottles of the same rum, aged for 36 net months with which the barrel is delivered at 37.5% Alc./Vol.",
  maintenanceOfBarrel: "Does the barrel require any maintenance?",
  maintenanceOfBarrelDescription: "The maintenance consists of always keeping it filled.",
  howCanICareMyBarrel: "What should I do to keep my barrel in good condition?",
  howCanICareMyBarrelDescription: "Keep it filled, in a place without sunlight, and with a constant temperature of 25ºC.",
  emptyMyBarrel: "Can I empty my barrel completely?",
  emptyMyBarrelDescription: "Yes, you can, however, remember to re-fill it as soon as possible.",
  whereIShouldHaveMyBarrel: "Where should I keep my barrel?",
  whereIShouldHaveMyBarrelDescription: "In a dry place, without direct sunlight, and with a constant temperature of 25ºC.",
  deliveryTime: "Delivery Time",
  deliveryTimeDescription: "Generally, we have a barrel inventory of all sizes. The delivery time in the Metropolitan Area is 5 working days. For other locations the delivery time is 8 working days.<br/>In case of not having the size of your selection, the delivery time is approximately 90 – 120 days. ",
  moreInformation2: "Need more information? Make an appointment with us <b><b><a class='a' href='mailto:privateprogram@rondonmiguel.com'>privateprogram@rondonmiguel.com</a></b></b>",
};

export default programs;
