const messages = {
  title: "Shopping Cart",
  subtitle: "My Cart / <b> {count} item(s) </b>",
  item: "Items",
  price: "Price",
  quantity: "Qty",
  total: "Total",
  btnCart: "Remove",
  btnPay: "Pay",
  btnGoToCart: "Go to Cart",
  taxesIncluded: "Taxes Included"
};

export default messages;
