<template>
<v-app>
  <v-main class="grey lighten-5">
    <v-container fluid class="pa-0 fill-height">
     <v-row class="fill-height " >
      <v-col cols="12" class="pa-0">
        <age-filter />
      </v-col>
    </v-row>
    </v-container>
  </v-main>
</v-app>
</template>

<script>
import AgeFilter from '@/components/welcome/age-filter.vue';

export default {
  name: 'AgeFilterContainer',

  components: {
    AgeFilter,
  },
};
</script>
