<template>
  <v-footer class="footer" padless>
    <v-row justify="center" no-gutters>
    <v-breadcrumbs :items="items" large
      class="copperplate breadcrumbs_footer px-0 justify-center">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to.name"
        :disabled="item.disabled"
      >
        {{ $t(item.text) }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
      <v-col class="py-4 text-center white--text" cols="12">
        <v-row class="justify-center justify-sm-end">
          <v-col cols="12" lg="6"
          class="text-center text-lg-left pl-sm-10 josefin-light
          white_black derechos_autor align-self-end">
           <v-row class="my-lg-0 mb-0  mt-n7 justify-center justify-lg-start">
              <v-img src="/cards.png" max-width="200" contain />
            </v-row>
        {{ new Date().getFullYear() }} — <strong>{{$t('menu.copyRight')}}</strong>
          </v-col>
          <v-col cols="12" lg="6" class="mt-lg-7" >
          <a href="https://mixart.do" target="_blank">
            <v-row class="justify-center justify-lg-end">
              <v-col cols="2" class="text-right align-self-end">
            <p class="josefin-light by_mixart mb-0">By Mixart</p>
              </v-col>
              <v-col cols="1" class="text-left align-self-end px-0">
            <v-img src="@/assets/logo_mixart.svg" class="logo_mixart mx-0 px-0" contain>
            </v-img>
            </v-col>
            </v-row>
          </a>
          </v-col>
    </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        text: 'menu.termAndConditions',
        to: {
          name: "/terminos"
        }
      },
      {
        text: 'menu.privacyPolicies',
        to: {
          name: '/politicas',
        },
      },
      {
        text: 'menu.contactUs',
        to: {
          name: '/contactanos',
        },
      },
    ],
  }),
};
</script>
