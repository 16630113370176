const messages = {
  title: "Contáctanos",
  weWantToKnowYou: "QUEREMOS SABER DE TI",
  knowMore: "Escríbenos si deseas conocer información adicional sobre nuestros productos.",
  ourTeam: "¿Quiéres formar parte de nuestro equipo?",
  ourTeamDescription: "Para formar parte de nuestro equipo solo tiene que descargar el formulario de solicitud de empleo, completar toda la información requerida y enviarlo a <a href='mailto:empleos@rondonmiguel.com' class='font-weight-bold'>empleos@rondonmiguel.com</a>, si tu perfil encaja dentro de alguna de nuestras vacantes disponibles un represetante del equipo de reclutamiento se pondrá en contacto con usted",
  btnDownloadForm: "Descargar Formulario",
  phone: "Teléfono",
  email: "Correo Eléctronico",
  office: "Oficina",
  name: "Nombre",
  message: "Mensaje",
  btnSend: "Enviar",
  infoProtected: "Este sitio está protegido por reCAPTCHA, <a href='https://policies.google.com/privacy'>las políticas de de privacidad de google</a><a href='https://policies.google.com/terms'> y los Terminos de Servicios</a>",
  findUsTitle: "Encuentrános",
  findUsDescription: "Encuentra nuestros puntos de ventas autorizados.",
};

export default messages;
