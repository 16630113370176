import http from './axios';

export default {
  async createAddress(address) {
    try {
      return await http.post(`/api/addresses/create?token=true`, address);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async updateAddress(id, address) {
    try {
      return await http.put(`/api/addresses/${id}?token=true`, address);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async getAddresses() {
    try {
      return await http.get(`/api/addresses?token=true`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },

};
