const messages = {
  title: "Mi Cuenta",
  personalInformation: "Información Personal",
  firstName: "Nombre",
  lastName: "Apellido",
  email: "Correo Electrónico",
  password: "Contraseña",
  passwordText: "Cambia tu contraseña",
  oldPassword: "Antigua contraseña",
  newPassword: "Nueva Contraseña",
  verifiedNewPassword: "Confirmar nueva contraseña",
  messageText: "Al actualizar tu contraseña recibirás un mensaje de confirmación a tu dirección de correo electrónico.",
  navigationList: "Lista de navegación",
  shippingInformation: "Información de Envío",
  purchaseHistory: "Historial de Compras",
  logOut: "Cerrar Sesión",
  btnUpdate: "Actualizar"

};

export default messages;
